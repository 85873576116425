import { IPriceDictItem, IPricePolicies, IPriceRulesResponse, IRawPriceRuleForRequest } from 'models/priceRules.model';
import { AuthHelper } from 'utils/authHelper.util';
import { IResultedOffersBody } from 'shared/models/product.model';
import { IPriceCalculateParams } from 'models/priceCalculate.model';

export class PricesApiService {
  static async getPricesDict(): Promise<Array<IPriceDictItem>> {
    return await AuthHelper.fetch('/api/v1/prices/dict');
  }

  static async getPriceRules(catalogId: number): Promise<IPriceRulesResponse> {
    return await AuthHelper.fetch('/api/v1/prices', {
      params: {
        catalogId,
      },
    });
  }

  static async updatePrices(catalogId: number, prices: Array<IRawPriceRuleForRequest>): Promise<IPriceRulesResponse> {
    return await AuthHelper.fetch('/api/v1/prices', {
      method: 'POST',
      params: { catalogId },
      body: prices,
    });
  }

  static async getPricePolicies(beId: number): Promise<IPricePolicies> {
    return await AuthHelper.fetch(`/api/v1/policies/${beId}`);
  }

  static async calculatePrice(params: IPriceCalculateParams): Promise<IResultedOffersBody> {
    return await AuthHelper.fetch<IResultedOffersBody>('/api/v1/price/calculate', {
      params,
      method: 'POST',
    }, false);
  }
}
